import React, { Fragment, useState } from 'react';
import * as PropTypes from 'prop-types';
import {
  Alert,
  Button,
  Col,
  Row,
  PopoverBody,
  Card,
  CardBody,
  CardHeader,
  UncontrolledPopover,
  Popover,
  UncontrolledTooltip
} from 'reactstrap';
import { QueryBuilder } from '@cubejs-client/react';
import ChartRenderer from '../ChartRenderer';
import MemberGroup from './MemberGroup';
import FilterGroup from './FilterGroup';
import TimeGroup from './TimeGroup';
import SelectChartType from './SelectChartType';
import OrderGroup from './Order/OrderGroup';
import Pivot from './Pivot/Pivot';
import { MAX_TABLE_PIVOT_SIZE } from '../../constants';
import { tablePivotLimitMessage } from '../../messages';
export default function ExploreQueryBuilder({
  vizState,
  cubejsApi,
  chartExtra,
  onVizStateChanged,
}) {
  const [dataSize, setDataSize] = useState(0);
  return (
    <QueryBuilder
      // Disabling default timegrouping by month
      // stateChangeHeuristics={(oldState, newState) => {
      //   if (newState.query == null) {
      //     return { ...oldState, ...newState}
      //   } else if ( (typeof newState.query.measures !== "undefined" && newState.query.measures.length > 0)
      //     && (oldState.query.measures !== newState.query.measures) ) {
      //     const dimension = newState.query.measures[0].replace('.count', '.createdAt');
      //     newState.query.timeDimensions = [ { dimension, granularity: "month"} ]
      //   }
      //   return newState;
      // }}
      disableHeuristics={true}
      initialVizState={vizState}
      cubejsApi={cubejsApi}
      wrapWithQueryRenderer={false}
      onVizStateChanged={onVizStateChanged}
      defaultChartType='table'
      render={({
        isQueryPresent,
        chartType,
        updateChartType,
        measures,
        availableMeasures,
        updateMeasures,
        dimensions,
        availableDimensions,
        updateDimensions,
        segments,
        availableSegments,
        updateSegments,
        filters,
        updateFilters,
        timeDimensions,
        availableTimeDimensions,
        updateTimeDimensions,
        orderMembers,
        updateOrder,
        pivotConfig,
        updatePivotConfig,
        cubejsApi,
        query,
        updateQuery,
      }) => {
        return (
          <>
            {/* Query controls */}
            <Row className='my-3 mx-1'>
              {/* Measure, dimension and filter control */}
              <Col>
                <Card className='border border-secondary'>
                  <CardBody className='shadow rounded'>
                    <Row>
                      {/* Measure control */}
                      <Col>
                        <MemberGroup
                          members={measures}
                          availableMembers={availableMeasures}
                          addMemberName='Medida'
                          updateMethods={updateMeasures}
                          updateQuery={updateQuery}
                        />
                      </Col>
                      {/* Dimension control */}
                      {Boolean(measures.length) &&
                      <Col>
                        <MemberGroup
                          members={dimensions}
                          availableMembers={availableDimensions}
                          addMemberName='Dimensão'
                          updateMethods={updateDimensions}
                          selectedMeasures={measures}
                        />
                      </Col>}
                      {/* Disabling segment button to mature the ideia */}
                      {/* <Col lg='2' md='6'>
                        <MemberGroup
                          members={segments}
                          availableMembers={availableSegments}
                          addMemberName='Segmento'
                          updateMethods={updateSegments}
                        />
                      </Col> */}
                    </Row>
                    <hr></hr>
                    <Row>
                      <FilterGroup
                        members={filters}
                        availableMembers={availableDimensions.concat(
                          availableMeasures
                        )}
                        addMemberName='Filtro'
                        updateMethods={updateFilters}
                        selectedDimensions={dimensions}
                        selectedMeasures={measures}
                      />
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              {/* Time period control */}
              { Boolean(measures.length) && <Col lg='4' md='4'>
                <Card className='border border-secondary'>
                  <CardBody className='shadow rounded'>
                    <Row>
                      <Col>
                        <TimeGroup
                          members={timeDimensions}
                          availableMembers={availableTimeDimensions}
                          addMemberName='Período'
                          updateMethods={updateTimeDimensions}
                          selectedMeasures={measures}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>}
            </Row>
            {/* Chart controls */}
            <Row className='my-3 mx-1'>
              <Col>
                <Card className='border border-secondary'>
                  <CardBody className='shadow rounded'>
                    <Row>
                      <Col lg='2'>
                        <SelectChartType
                          chartType={chartType}
                          updateChartType={updateChartType}
                          dataSize={dataSize}
                        />
                      </Col>
                      <Col lg='2'>
                      <Button
                          id='order-popover-button'
                          color='success'
                          disabled={!isQueryPresent}
                        >
                          <i className='fas fa-sort-alpha-down mr-2'></i> Ordenar
                        </Button>
                        <UncontrolledPopover
                          id='order-popover'
                          target='order-popover-button'
                          placement='bottom'
                          trigger='legacy'
                        >
                          <PopoverBody style={{ minWidth: 400 }}>
                            <OrderGroup
                              orderMembers={orderMembers}
                              onReorder={updateOrder.reorder}
                              onOrderChange={updateOrder.set}
                            />
                          </PopoverBody>
                        </UncontrolledPopover>
                      </Col>
                      {/* Commenting for futher implementation */}
                      {/* {chartType === 'table' && (
                        <Col className="col-md-auto">
                          <span id='table-pivot' className='tooltip-wrapper disabled'>
                            <Button
                              id='pivot-popover-button'
                              color='success'
                              disabled={!isQueryPresent || dataSize > MAX_TABLE_PIVOT_SIZE}
                            >
                              <i className='fas fa-retweet mr-2'></i> Organizar tabela
                            </Button>
                          </span>
                          {dataSize > MAX_TABLE_PIVOT_SIZE && (<UncontrolledTooltip
                              placement='right'
                              target='table-pivot'
                            >
                              { tablePivotLimitMessage }
                            </UncontrolledTooltip>)}
                          <UncontrolledPopover
                            id='pivot-popover'
                            target='pivot-popover-button'
                            placement='bottom'
                            trigger='legacy'
                          >
                            <PopoverBody style={{ minWidth: 400 }}>
                              <Pivot
                                pivotConfig={pivotConfig}
                                onMove={updatePivotConfig.moveItem}
                                onUpdate={updatePivotConfig.update}
                              />
                            </PopoverBody>
                          </UncontrolledPopover>
                        </Col>)} */}
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {/* Chart renderer */}
            <Row className='my-3 mx-1'>
              <Col>
                {isQueryPresent ? (
                  <Card
                    style={{
                      minHeight: 420,
                    }}
                    className='border border-secondary'
                  >
                    <CardHeader className='border-bottom border-secondary bg-secondary'>
                      {chartExtra}
                    </CardHeader>
                    <CardBody className='rounded central-xy'>
                      <ChartRenderer
                        vizState={{ query, chartType, pivotConfig, }}
                        cubejsApi={cubejsApi}
                        dataSizeState={{dataSize, setDataSize}}
                      />
                    </CardBody>
                  </Card>
                ) : (
                  <div className='p-3 text-center container-sm'>
                    <Alert
                      className='alert-default'
                      style={{ margin: '0 auto 20px' }}
                    >
                      <h2 className='text-white mb-4'>
                        Escolha uma medida acima para começar
                      </h2>
                      <p className='text-white text-justify'>
                        As <strong>medidas</strong> representam quantidades,
                        como o número de proposições que você acompanha. Já as{' '}
                        <strong>dimensões</strong> são as características dos
                        objetos como, por exemplo, a prioridade das proposições.
                      </p>
                      <p className='text-white text-justify'>
                        Você pode adicionar uma medida e combiná-la com uma ou mais dimensões.
                      </p>
                    </Alert>
                  </div>
                )}
              </Col>
            </Row>
          </>
        );
      }}
    />
  );
}
ExploreQueryBuilder.propTypes = {
  vizState: PropTypes.object,
  cubejsApi: PropTypes.object,
  chartExtra: PropTypes.array,
};
ExploreQueryBuilder.defaultProps = {
  vizState: {},
  cubejsApi: null,
  chartExtra: null,
};
