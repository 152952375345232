import './body.css';
import 'antd/dist/antd.min.css';
import React, { useEffect, useState } from 'react';
import '@ant-design/compatible';
import cubejs from '@cubejs-client/core';
import TagManager from 'react-gtm-module';
import { CubeProvider } from '@cubejs-client/react';
import IndexNavbar from './components/IndexNavbar';
import { setUserInfoLocalStorage } from './helpers';

const queryString = require('query-string');

const loginURI = process.env.REACT_APP_LOGIN_URI;
const API_URL = process.env.REACT_APP_API_URL;
let cubejsApi = null;
const regex = new RegExp('jwt');
let token = ""

const TagManagerArgs = {
  gtmId: 'GTM-T87PX6C'
};

TagManager.initialize(TagManagerArgs);

function App({children}) {
  const [loading, setLoading] = useState(true);

  const logUser = async() => {
    if(regex.test(window.location.href)) {
      const hash = queryString.parse(window.location.href.split("?")[1].replace(new RegExp("#/.*$"), ""))
      const fusionToken = hash.jwt
      
      if(fusionToken && fusionToken.length > 10) {
        token = fusionToken
        window.localStorage.setItem("jwt", token)
      }
    }
  
    fetch(loginURI + "/user", {
      credentials: 'include' // fetch won't send cookies unless you set credentials
    })
    .then(response => response.json())
    .then((response) => {
      if(Object.keys(response).length === 0) {
        window.location.href = loginURI + "/login";
      } else {
        if(window.localStorage.getItem("jwt")) {
          token = window.localStorage.getItem("jwt")
        }

        setUserInfoLocalStorage(response);

        cubejsApi = cubejs(token, {
          apiUrl: `${API_URL}/dashboards/v1/dashboards`,
        });
        
        setLoading(false);
      }
    })
  }

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      const dummyResponse = {
        user: { email: 'user@example.com'}
      }
      setUserInfoLocalStorage(dummyResponse);
      cubejsApi = cubejs(process.env.REACT_APP_TOKEN, {
        apiUrl: `${API_URL}/dashboards/v1/dashboards`,
      });
      setLoading(false);
    } else {
      logUser()
    }
  }, [])

  const loadingComponent = (
    <div className='auth-container'>
      <img
        alt='Logo Inteligov'
        src='https://s3.amazonaws.com/www.zenlobby.com.assets/brands/symbols/inteligov.svg'
        className='auth-logo'
      />
      <h1>Autenticando</h1>
    </div>
  );
  const appComponent = (
    <CubeProvider cubejsApi={cubejsApi}>
        <IndexNavbar />
        {children}
    </CubeProvider>
  );

  return (loading ? loadingComponent : appComponent)
}

export default App;
