import { MAX_BARS_NUMBER, MAX_PIE_SLICES } from "../constants";

const dataParserKeyValue =  (resultSet, chartType) => {
  const keyValue = resultSet.seriesNames()[0].key;
  const pivot = resultSet.chartPivot();
  const typeSizeCheck = {
    bar: MAX_BARS_NUMBER,
    pie:  MAX_PIE_SLICES,
  }
  const data = pivot.slice(0, typeSizeCheck[chartType] || null).map((element) => {
    return {
      key: element.x,
      value: element[keyValue]
    }
  });
  return data;
}

export default dataParserKeyValue;
