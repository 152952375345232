import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import capitalizeFirstLetter from './capitalizeFirstLetter';

const displayDate = (date, granularity) => {

  const formatDate = {
    day: format(parseISO(date),"PPP", {locale: ptBR}),
    week: format(parseISO(date),"PPP", {locale: ptBR}),
    month: capitalizeFirstLetter(format(parseISO(date),"MMMM 'de' yyyy", {locale: ptBR})),
    quarter: capitalizeFirstLetter(format(parseISO(date),"MMMM 'de' yyyy", {locale: ptBR})),
    year: format(parseISO(date),"yyyy"),
  };

  return formatDate[granularity] || capitalizeFirstLetter(format(parseISO(date),"MMMM 'de' yyyy", {locale: ptBR}));
}

export default displayDate;